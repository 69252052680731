import React from "react"
import styled from "styled-components"
import Grid from "../../styles/grid"
import facebook from "../../images/svg/SoMe/facebook.svg"
import linkedIn from "../../images/svg/SoMe/linkedIn.svg"
import instagram from "../../images/svg/SoMe/instagram.svg"
import ALFA from '../../images/ALFA-logo.png'

import sightline from "../../images/svg/partners/sightline.svg"
import konovalenko from "../../images/svg/partners/konovalenko.svg"
import sff from "../../images/svenskfastighetsformedling.png"
import sundprojekt from "../../images/svg/partners/sundprojekt.svg"

const Footer = () => {
  return (
    <FooterStyle>
      <Grid className="grid">
        <p>
          Bostadsområdet Kalcium i Malmö utvecklas gemensamt av bostadsprojektutvecklarna <br /><br />Sundprojekt och ALFA Development.
        </p>
        <br />
        <p>
          Med byggmästartradition sedan 1942 utvecklar och bygger Sundprojekt hyres- och bostadsrätter på attraktiva lägen i Öresundsregionen, främst i Malmö, Lund och Helsingborg. <a style={{ color:"#ffffff"}} href="https://www.sundprojekt.se">Läs mer</a>.
        </p>
        <img
          src={sundprojekt}
          alt="sundprojekt"
        /><br />
        <p>
          ALFA Development är ett danskt bostadsutvecklingsföretag som har genomfört en lång rad prestigefulla bostadsprojekt och sedan 2017 är aktivt även på den svenska marknaden. <a style={{ color:"#ffffff"}} href="https://www.elfdevelopment.com/">Läs mer</a>.<br />
        </p>
        <img
          src={ALFA}
          alt="ALFA Development"
          style={{maxWidth: 170 }}
        />
        <br /><br />
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/sundprojekt"><img style={{ margin: "5px" }} src={facebook} alt="facebook ikon" /></a>
        <a target="_blank" href="https://www.instagram.com/sundprojekt/?hl=en" rel="noreferrer"><img style={{ margin: "5px" }} src={instagram} alt="instagram ikon" /></a>
        <a target="_blank" href="https://www.linkedin.com/company/sundprojekt/" rel="noreferrer"><img style={{ margin: "5px" }} src={linkedIn} alt="linkedin ikon" /></a>
      </Grid>
      <Sammarbete>
        <p>I samarbete med:</p>
        <div className="imageContainer">
        <a target="_blank" rel="noreferrer" href="https://www.sightline.se/?lang=en"><img src={sightline} alt="sightline logo" /></a>
        <a target="_blank" rel="noreferrer" href="https://konovalenko.se/"><img src={konovalenko} alt="konovalenko logo" /></a>
        <a target="_blank" rel="noreferrer" className="svenskfast" href="https://www.svenskfast.se/"><img src={sff} alt="sff logo" /></a>
        </div>
      </Sammarbete>
    </FooterStyle>
  )
}

const FooterStyle = styled.footer`
  background: rgba(44, 27, 14, 0.7);

  color: #fff;
  text-align: center;
  border-top: 1px solid #fff;

  .grid {
    padding: 2rem 4rem;
  }

  p {
    max-width: 720px;
    margin: 15px auto;
  }

  a {
    transition: ease-in-out 0.3s;
    
    img {
      transition: ease-in-out 0.2s;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 800px) {
    .grid {
      padding: 2rem;
    }
  }

  @media only screen and (max-width: 500px) {
    .grid {
      padding:2rem 1rem;
    }
  }
`

const Sammarbete = styled.div`
  background-color: #736459;
  padding: 2rem;
  p {
    margin-bottom: 25px;
    margin-top: 0px;
    font-weight:400;
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    img {
      margin-bottom: 21px !important;
      margin: 0px 15px;

      &:first-child {
        position: relative;
        top: 5px;
      }
    }
  }

  .svenskfast img{
    height: 35px;
  }

  @media only screen and (max-width: 650px) {
    .imageContainer {
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
     
      img {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 2rem;
      }
    }
  }
`

export default Footer
