
import styled from 'styled-components'

const Grid = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 2rem;
  // testing
  @media only screen and (max-width: 500px) {
    padding: 0 1rem;
  }
`

export default Grid
