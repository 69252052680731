import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import React, { useState, useRef, useEffect } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Header = props => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [menuStatus, setMenuStatus] = useState("closed")
  const menuButton = useRef()

  const onHamburgerClick = () => {
    if (menuStatus === "closed") {
      setMenuStatus("open")
    } else {
      setMenuStatus("closed")
    }
  }
  
  const onLinkClick = () => {
    if (menuStatus === "open") {
      menuButton.current.checked = false
      setMenuStatus("closed")
    } 
  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if(window.scrollY < 200){
        setIsScrolled(false);
      }else{
        setIsScrolled(true);
      }
    });
  });

  return ( 
    <StyledHeader>
      <div>
        <nav className={`top-nav ${isScrolled ? "top-nav-bg" : ""}`} id="top-nav">
          <Link to="/" className="logo">
            <Img fluid={props.iconImage} alt="" />
          </Link>
          <input ref={menuButton} className="menu-btn" type="checkbox" id="menu-btn" />
          <label
            className="menu-icon"
            htmlFor="menu-btn"
            onClick={onHamburgerClick}
          >
            <span className="navicon"></span>
          </label>
          <ul className={`menu ${menuStatus}`}>
            <li className="mobileHome">
              <Link to="/">
                <Img fluid={props.iconImage} alt="Logo" />
              </Link>
            </li>
            <li>
              <a href="https://cdn.sightline.se/1584a7c4a62afa81/psw/Elinegard/" target="_blank">Hitta ditt boende</a>
            </li>
            {/* <li>
              <AnchorLink onClick={onLinkClick} href="#form">Köpanmälan</AnchorLink>
            </li> */}
            <li>
              <Link onClick={onLinkClick} to="/radhus">Radhus</Link>
            </li>
            <li>
              <Link onClick={onLinkClick} to="/lagenheter">Lägenheter</Link>
            </li>
            <li>
              <Link onClick={onLinkClick} to="/omradet">Området</Link>
            </li>
            <li>
              <Link onClick={onLinkClick} target="_blank" to="https://showroom.homemaker.io/sales-start/UA7QciKx">Tillval</Link>
            </li>
            <li>
              <Link onClick={onLinkClick} to="/bilder-dokument">Bilder & Bofakta</Link>
            </li>
            <div className="blurMe"></div>
          </ul>
        </nav>
      </div>
    </StyledHeader>
  )
}
const StyledHeader = styled.header`
  @keyframes menuFadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes menuFadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
  
  .open li {
    opacity: 0;
    animation: menuFadeIn 1s;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  .closed li {
    opacity: 1;
    animation: menuFadeIn 1s;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  .logo {
    max-width: 68px;
    height: 68px;
    border-right: 1px solid #fff;
    padding: 10px;

    img {
      margin-bottom: 0 !important;
      object-fit: contain;
    }
  }

  .top-nav {
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: top 0.75s ease-in;
    background: transparent;
    border-bottom: 1px solid #fff;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 300%;
      pointer-events: none;
      z-index: -1;
    }
    .menu {
      list-style: none;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-height: 0vh;
      margin-bottom: 0;
      // transition: max-height .5s ease-out;
      margin-left: 0;
      text-align: right;

      .blurMe {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: -2;
        backdrop-filter: blur(0px);
        background: rgba(0, 0, 0, 0);
        transition: ease-in-out 0.5s;
      }

      li a {
        display: inline-block;
        min-width: 75px;
        margin: 0.3em 0;
        padding: 0 10px;
        transition: 1s all;
        text-decoration: none;
        font-size: 14px;
        color: #fff !important;
        font-family: helvetica;
        text-transform: uppercase;

        &:link,
        &:visited {
          color: #0d0d0d;
        }

        &:focus,
        &:hover,
        &:active {
          color: #000;
        }
      }
    }

    .logo {
      display: none;
      width: 150px;
    }

    .menu-icon {
      cursor: pointer;
      display: inline-block;
      padding: 28px 20px;
      position: absolute;
      right: 0;
      top: 6px;
      z-index: 99;

      .navicon {
        background: transparent;
        display: block;
        height: 1px;
        position: relative;
        width: 40px;

        &:before,
        &:after {
          background: #fff;
          content: "";
          display: block;
          height: 100%;
          position: absolute;
          transition: all 0.2s ease-out;
          width: 100%;
        }

        &:before {
          top: 7px;
          -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
        }

        &:after {
          top: -7px;
          -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
        }
      }
    }

    .menu-btn {
      display: none;
      position: relative;
      max-height: 100vh;
      height: 100vh;
      z-index: -1;

      &:checked ~ .menu {
        position: relative;
        max-height: 100vh;
        height: 100vh;
        z-index: 1;

        li {
          width: 100%;
          &:first-child {
            padding-top: 80px;
          }

          a {
            opacity: 1;
          }
        }

        .blurMe {
          position: absolute;
          width: 100%;
          height: 100%;
          background: transparent;
          z-index: -2;
          backdrop-filter: blur(10px);
          background: rgba(0, 0, 0, 0.7);
        }
      }

      &:checked ~ .menu-icon .navicon {
        background: transparent;

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }

      &:checked ~ .menu-icon:not(.steps) .navicon {
        &:before,
        &:after {
          top: 0;
        }
      }
    }
  }

  // MOBILE MENU CSS

  @media (max-width: 1100px) {
    .mobileHome {
      a {
        padding: 0 !important;
        padding-bottom: 0 !important;
        border-bottom: none !important;
      }
      .gatsby-image-wrapper {
        border: 1px solid #fff;
        width: 80px;
        height: 80px;
        img {
          padding: 10px;
          margin-bottom: 0;
        }
      }
    }

    nav#top-nav {
      height: 35px;
      border-bottom: 0;
    }
    .menu {
      li {
        width: 100%;
        padding-right: 20px;

        a {
          font-size: 25px;
          border-bottom: 1px solid #fff;
          padding-right: 0 !important;
          padding-bottom: 15px !important;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }

  @media (max-height: 600px) {
    .mobileHome {
      padding-right: 5px !important;
      
      .gatsby-image-wrapper {
        width: 60px;
        height: 60px;
      }
    }
    .menu li a {
      padding-bottom: 0 !important;
    }
  }

  // DESKTOP MENU CSS

  @media (min-width: 1100px) {
    .mobileHome {
      display: none;
    }
    .top-nav {
      position: fixed;
      background: none;
      transition: all ease .5s;
    }
    .top-nav-bg {
      background: #4e3c368f;
      transition: all ease .5s;
    }

    .top-nav li {
      margin: 0 10px;
    }

    nav#top-nav .logo {
      display: inline-block;
    }

    .top-nav .menu {
      width: calc(100% - 68px);
      max-height: none;
      flex-direction: row;
      justify-content: center;
      margin-left: 0;
      padding: 15px 0;
    }
    .top-nav .menu-icon {
      display: none;
    }
  }
`

export default Header
